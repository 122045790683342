import { render, staticRenderFns } from "./PlanMovil.vue?vue&type=template&id=1a3e8a0e&scoped=true&"
import script from "./PlanMovil.vue?vue&type=script&lang=js&"
export * from "./PlanMovil.vue?vue&type=script&lang=js&"
import style0 from "./PlanMovil.vue?vue&type=style&index=0&id=1a3e8a0e&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1a3e8a0e",
  null
  
)

export default component.exports